import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '../src/assets/css/fontawesome-all.min.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'swiper/css';
import 'swiper/css/effect-fade';

import { gtag, install } from 'ga-gtag';
install('G-9ZMTT7ZTPN', { 'send_page_view': false });

router.afterEach(to => {
	const query = Object.assign({}, to.query);
	let utm = {};
	for (let key in query) {
		if (key.match(/^utm\_/)) {
			utm[key.split('_')[1]] = query[key];
			delete query[key];
		}
	}

	if (Object.keys(utm).length) {
		// we have utm params, let's save them in local storage
		localStorage.setItem('gtag_utm', JSON.stringify(utm));
	} else {
		// we don't have utm params, let's check local storage
		utm = JSON.parse(localStorage.getItem('gtag_utm') || '{}');
	}
	gtag('set', 'campaign', utm);
	gtag('event', 'page_view');
	if (Object.keys(query).length != Object.keys(to.query).length) {
		router.replace({ query });
	}
});

const app = createApp(App)
app.use(router);
app.mixin({
	computed: {
		constants () {
			const scheduleButtonText = process.env.VUE_APP_SCHEDULE_BUTTON_TEXT;
			return { scheduleButtonText };
		}
	}
});
app.mount("#app");
