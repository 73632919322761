import { createRouter, createWebHistory } from 'vue-router';
import { seoGuard } from '@aminoeditor/vue-router-seo';

const seoDefaults = {
	title: 'Senior Yoga Orange County',
	metaTags: [
		{
			name: 'description',
			content: 'Private yoga sessions to help prevent falling and the collapse of muscles and organs. Daily functional skills such as bending, walking, balance and core stability are restored and strengthened to help prevent major injuries and keep seniors independent'
		},
		{
			name: 'keywords',
			content: 'yoga,senior,fall prevention,senior care,anti aging,strength,longevity,orange county,mobility'
		}
	]
}

const routes = [
    {
        meta: {
            seo: {
                ...seoDefaults
            }
        },
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        meta: {
            seo: {
                ...seoDefaults
            }
        },
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/HomeProductShowcase.vue'),
    },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach(seoGuard);

export default router;
